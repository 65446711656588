@tailwind base;
@tailwind components;
@tailwind utilities;
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  /* Prevents overflow on the x-axis */
  overflow-x: hidden;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.sky {
  background-size: 550px 550px, 350px 350px, 250px 250px, 150px 150px, 100% 100%;
  background-position: 0 0, 40px 60px, 130px 270px, 70px 100px, center center;
  background-blend-mode: soft-light;
}

.comet {
  transform-origin: center center;
  animation: comet 8s linear infinite;
}

@keyframes comet {
  0%,
  40% {
    transform: translateX(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  60%,
  100% {
    transform: translateX(-100vmax);
    opacity: 0;
  }
}

.form-control {
  -webkit-transition: none;
  transition: none;
  width: 32px;
  height: 32px;
  text-align: center;
}

.form-control:focus {
  color: #3f4254;
  background-color: #ffffff;
  border-color: #884377;
  outline: 0;
}

.form-control.form-control-solid {
  background-color: #f3f6f9;
  border-color: #f3f6f9;
  color: #3f4254;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease;
}

.form-control.form-control-solid:active,
.form-control.form-control-solid.active,
.form-control.form-control-solid:focus,
.form-control.form-control-solid.focus {
  background-color: #ebedf3;
  border-color: #ebedf3;
  color: #3f4254;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease;
}
